<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card v-if="editedItem">
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text v-if="fromActivityList">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <v-autocomplete
                item-value="ID"
                item-text="Name"
                v-model="editedItem.ManagerActivityID"
                :items="activityList"
                outlined
                dense
                chips
                small-chips
                label="Activity"
                hide-details
              />
              <!--v-select
                item-value="ID"
                item-text="Name"
                :items="activityList"
                v-model="editedItem.ManagerActivityID"
                label="Activity"
                outlined
                hide-details
              /-->
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <interval-times
                :label="`${$t('booking.scheduleForm.startTime')}:`"
                v-model="editedItem.Time"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <interval-times
                :label="`${$t('booking.scheduleForm.endTime')}:`"
                v-model="editedItem.TimeEnd"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="!fromActivityList">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <translate
                v-model="editedItem.Title"
                label="T�tulo"
                :languages="languages"
                type="text"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <translate
                v-model="editedItem.Place"
                label="Lugar"
                :languages="languages"
                type="text"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                item-value="ID"
                item-text="Name"
                :items="categories"
                v-model="editedItem.CategoryID"
                label="Categor�a"
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="padding: 10px 20px;"
            >
              <translate
                v-model="editedItem.Description"
                label="Descripci�n"
                :languages="languages"
                type="text"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              
              <small>Im�gen: (si no se informa se muestra la categor�a)</small>
              <div style="padding-top:20px;">
                <select-image 
                  :key="editedItem.lmu"
                  :image="editedItem.Image"
                  :onChange="handleChangeImage"
                  :locale="locale"
                  :folder="workspaceID"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-checkbox
                v-model="editedItem.Reservable"
                hideDetails
                label="Reservable"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="editedItem.Reservable"
            >
              <v-text-field
                v-model="editedItem.PAX"
                label="Cupo"
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-if="editedItem.Reservable"
            >
              <v-text-field
                v-model="editedItem.PVPHosted"
                label="PVP Alojado"
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-if="editedItem.Reservable"
            >
              <v-text-field
                v-model="editedItem.PVPNoHosted"
                label="PVP Alojado"
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-if="!fromManager"
            >
              <interval-times
                :label="`${$t('booking.scheduleForm.startTime')}:`"
                v-model="editedItem.Time"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-if="!fromManager"
            >
              <interval-times
                :label="`${$t('booking.scheduleForm.endTime')}:`"
                v-model="editedItem.TimeEnd"
              />
            </v-col>
            <!--v-col
              cols="12"
              sm="12"
              md="12"
            >
              <select-image 
                :key="editedItem.lmu"
                :image="editedItem.Image"
                :onChange="handleChangeImage"
                :locale="locale"
                :folder="workspaceID"
              />
            </v-col-->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <delete-item
            v-if="editedItem.ID"
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            
          />
          <v-btn
            @click="() => onClose(null)"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
            :disabled="fromActivityList && !editedItem.ManagerActivityID"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
import Translate from '@/components/Translate'
import IntervalTimes from '@/components/intervalTimes/Index'
import DeleteItem from '@/components/DeleteItem' 
export default {
  components: {
    SelectImage,
    Translate,
    IntervalTimes,
    DeleteItem,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    contentTypeDataID: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    contentTypeData: {
      type: [Object, String],
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    fromManager: {
      type: Boolean,
      default: false,
    },
    activityList: {
      type: Array,
      default: null,
    },
    fromActivityList: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  computed: {
    categories () {
      if (!this.contentTypeData) return
      const aux = JSON.parse(JSON.stringify(this.contentTypeData))
      return aux ? aux.Categories.map(item => {
        item.Name = utils.getTranslation(item.Name, this.locale)
        return item
      }) : null
    },
    /*
    languages () {
      return ['es', 'en', 'fr', 'de', 'ca']
    },*/
  },
  watch: {
    value () {
      this.prepareValue()
    },
    'editedItem.Time' (v) {
      if (!this.editedItem) return
      this.editedItem.TimeEnd = v + 60
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      const aux = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      if (aux) aux.lmu = new Date().toISOString()
      this.editedItem = aux
      this.dialog = this.editedItem ? true : false
    },
    handleChangeImage (v) {
      if (!v) return 

      this.editedItem.Image = v.base64
      this.editedItem.newImage = v
      this.editedItem.lmu = new Date().toISOString()
    },
    handleSave () {
      const body = {
        ID: this.editedItem.ID,
        WorkspaceID: this.workspaceID,
        ManagerActivityID: this.fromActivityList ? this.editedItem.ManagerActivityID : null,
        Date: this.editedItem.date,
        Title: this.editedItem.Title,
        Description: this.editedItem.Description,
        Place: this.editedItem.Place,
        Time: this.editedItem.Time,
        TimeEnd: this.editedItem.TimeEnd,
        CategoryID: this.editedItem.CategoryID,
        Image: this.editedItem.Image,
        newImage: this.editedItem.newImage,
        Reservable: this.editedItem.Reservable,
        PVPHosted: this.editedItem.PVPHosted,
        PVPNoHosted: this.editedItem.PVPNoHosted,
        PAX: this.editedItem.PAX && this.editedItem.PAX.length > 0 ? this.editedItem.PAX | 0 : null
      }
      if (this.editedItem.ID) {
        api.updateItem ('dviewcontent', `v1/private/activity-barcelo${this.fromManager ? '-manager' : ''}/${this.contentTypeDataID}/`, this.editedItem.ID, body)
          .then(() => {   
            this.onClose(true)
          })
      } else {
        api.addItem ('dviewcontent', `v1/private/activity-barcelo${this.fromManager ? '-manager' : ''}/${this.contentTypeDataID}`, body)
          .then(() => {   
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      api.deleteItem ('dviewcontent', `v1/private/activity-barcelo${this.fromManager ? '-manager' : ''}/${this.contentTypeDataID}/`, this.editedItem.ID)
        .then(() => {   
          this.onClose(true)
        })
    },
  },
}
</script>
