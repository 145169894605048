<template>
  <div>
  <table 
    v-if="contentTypeDataID && weekDay"
    cellspacing="0" cellpadding="0"
    style="border: 5px solid white;width: 100%;margin-bottom: 20px;border-radius: 5px;border-spacing: 5px 0 5px 5px !important;background-color:white;" 
  >
    <tr style="height: 60px;">
      <td colspan="7" style="vertical-align: middle;text-align: center; padding: 0 10px;font-size:1.2rem;background-color: white !important;">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            style="text-align: left;"
          >
            <totem-preview
              :workspaceID="workspaceID"
              :locale="locale"
              color="primary"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              @click="() => { selectedWeek-- }"
                style="margin-right: 20px;"
                color="primary"
                dark
            >
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
              <b> {{getTranslation(contentTypeData.Title, locale)}}</b> <small>[{{getRange(weekDay)}}]</small>
            <v-btn
              @click="() => { selectedWeek++ }"
                style="margin-left: 20px;"
                color="primary"
                dark
            >
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            style="text-align: right;"
          >
            <!--v-select
              item-value="ID"
              item-text="Name"
              :items="categories"
              v-model="categoryFilter"
              label="Categor�a"
              outlined
              hide-details
              small
            /-->
            <v-btn-toggle
              v-model="eventType"
              borderless
              color="primary"
              mandatory
                dense
            >
              <v-btn value="cardOff">
                <v-icon>
                  mdi-card-bulleted-off
                </v-icon>
              </v-btn>
              <v-btn value="cardOn">
                <v-icon>
                  mdi-card-bulleted
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <form-event
          v-if="contentTypeData && activityList"
          :value="editedItem"
          :onClose="handleCloseForm"
          :contentTypeData="contentTypeData"
          :contentTypeDataID="contentTypeDataID"
          :locale="locale"
          :activityList="activityList"
          :fromActivityList="fromActivityList"
          :workspaceID="workspaceID"
          :languages="languages"
        />
      </td>
    </tr>
  <!-- ini meal-->
    <tr style="height: 50px;background-color:#cccccc80;">
      <th
        v-for="(day, index) of weekDay"
        :key="`day${day.date}`"
        style="width: 12.5%;padding: 5px 5px;color:black;border-left: 2px solid white;border-right: 2px solid white;" 
      >
        <v-btn 
          :dark="!day.old" 
          :color="contentTypeData.Color"
          block
          @click="handleAdd(day.date)"
          :disabled="day.old"
        >
          {{ $t(`common.s${index}`, locale)}} {{day.date.split('-').pop()}}
              <v-icon right dense v-if="!day.old">mdi-plus</v-icon>
        </v-btn>
      </th>
    </tr>
    <tr>
      <td
        v-for="(day) of weekDay"
        :key="`list${day.date}`"
        style="width: 12.5%;padding: 5px 5px;color:black;vertical-align: top;background-color:#cccccc80;border-left: 2px solid white;border-right: 2px solid white;" 
      >
        <list-day
          v-if="contentTypeDataID && activityList"
          :contentTypeData="contentTypeData"
          :contentTypeDataID="contentTypeDataID"
          :day="day"
          :onEdit="(v) => { editedItem = v }"
          :locale="locale"
          :forceRefresh="forceRefresh"
          :categoryFilter="categoryFilter"
          :activityList="activityList"
          :eventType="eventType"
          :old="day.old"
        />
      </td>
    </tr>
  </table>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import TotemPreview from './TotemPreview'
import ListDay from './ListDay'
import FormEvent from './Form'
export default {
  components: {
    TotemPreview,
    ListDay,
    FormEvent,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    contentTypeDataID: {
      type: String,
      required: true,
    },
    contentTypeData: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onEdit: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    weekDay: null,
    selectedWeek: 0,
    editedItem: null,
    forceRefresh: null,

    activityList: null,
    eventType: 'cardOn',
    categoryFilter: null,
  }),
  computed: {
    fromActivityList () {
      return !this.fromManager && this.contentTypeData.SelectableActivities ? true : false
    },
    categories () {
      if (!this.contentTypeData) return
      const aux = JSON.parse(JSON.stringify(this.contentTypeData))
      return aux ? aux.Categories.map(item => {
        item.Name = utils.getTranslation(item.Name, this.locale)
        return item
      }) : null
    },
  },
  watch: {
    'contentTypeData.SelectableActivities' () {
      this.handleGetSelectableActivities()
    },
    contentTypeDataID () {
      this.handleGetSelectableActivities()
      this.handlePrepareWeekDay()
    },
    selectedWeek () {
      this.handlePrepareWeekDay()
    },
    eventType (v) {
      localStorage.setItem("eventTypeDviewcontent", v)
    },
  },
  mounted () {
    this.prepareEventType()
    this.handleGetSelectableActivities()
    this.handlePrepareWeekDay()
  },
  methods: {
    prepareEventType () {
      if (localStorage.getItem('eventTypeDviewcontent') && localStorage.getItem('eventTypeDviewcontent').length > 0 && localStorage.getItem('eventTypeDviewcontent') !== this.eventType) this.eventType = localStorage.getItem('eventTypeDviewcontent') 
    },
    handleGetSelectableActivities () {
      this.activityList = null
      if (!this.fromActivityList) {
        this.activityList = []
        return
      } else {
        const url = `v1/private/activity-barcelo-manager/${this.contentTypeDataID}`
        api.getAll ('dviewcontent', url, 0, 1000, '')
          .then(response => {
            if (response) {
              this.activityList = response.data.map (item => {
                item.Name = this.getTranslation(item.Title, this.locale)
                item.Title = JSON.parse(item.Title)
                item.Description = JSON.parse(item.Description)
                item.Place = JSON.parse(item.Place)
                return item
              })
            }
            this.loading = false
          })
          .catch (error => {
            console.log(error)
          })
      }
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    getRange (v) {
      let ini = v[0].date.split('-')
      ini.shift()
      let end = v[6].date.split('-')
      end.shift()
      return `del ${ini.join('/')} al ${end.join('/')}`
    },
    addDays (v, daysToAdd) {
      return new Date(new Date(v).setDate(new Date(v).getDate() + daysToAdd))
    },
    handlePrepareWeekDay () {
      const result = []
      const today = this.selectedWeek ? this.addDays(new Date(), this.selectedWeek * 7) : new Date()
      let actualDay = ((today.getDay() === 0 ? 6 : today.getDay()))*-1
      for (let i = 0; i < 7; i++) {
        actualDay++
        result.push({
          contentTypeDataID: this.contentTypeDataID,
          i,
          date: this.addDays(today, actualDay).toISOString().split('T').shift(),
          old: this.selectedWeek < 0 || (this.selectedWeek === 0 && actualDay < 0)
        })
      }
      this.weekDay = result
    },
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
    handleAdd (v) {
      this.editedItem = {
        CategoryID: this.contentTypeData.Categories[0].ID,
        Title: { es: '' },
        Place: { es: ''},
        Time: 960,
        TimeEnd: 1020,
        date: v,
      }
    },
  },
}
</script>
